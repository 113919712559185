/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
  font-family: 'Roboto-bold';
  src: url('assets/font/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-black';
  src: url('assets/font/Roboto/Roboto-Black.ttf');
} */
body {
  font-family: 'Montserrat', sans-serif;
}

html {
  font-family: 'Montserrat', sans-serif;
}

#containerApp .site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.footer-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-menu li {
  margin: 0;
  padding: 0;
}

.footer-menu li a {
  font-size: 17px;
}

.adm-list-item-content,
.adm-list-body {
  border: none !important;
}

.ant-drawer-title {
  color: white;
}

.ant-select-selector {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.calendar-picker .ant-picker-calendar-date-value {
  text-align: center;
}

.calendar-picker .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-picker .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  margin: 0;
  border: none !important;
}

.calendar-picker .ant-picker-content td {
  border: 1px solid #bbb;
}

.calendar-picker .ant-picker-cell-today .ant-picker-calendar-date-value {
  font-weight: bold;
  background-color: #2d7cdc;
  color: white !important;
  border-radius: 100%;
  display: block;
  height: 24px;
  width: 24px;
  margin: 0 auto;
}

.calendar-picker .ant-picker-calendar-date-today {
  background: white !important;
  border: none !important;
}

.calendar-picker .ant-picker-content thead th {
  border: 1px solid #bbb;
  text-align: center;
  font-weight: bold;
  padding: 6px !important;
  background: #2d7cdc;
  color: white;
}

.calendar-picker .ant-picker-body {
  padding: 0 !important;
}

.calendar-picker .ant-picker-cell-disabled {
  background-color: #ddd;
}

.calendar-picker
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-disabled.ant-picker-cell-selected
  .ant-picker-calendar-date {
  background-color: #ddd !important;
}

.calendar-picker
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-disabled.ant-picker-cell-selected
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.25) !important;
}

.calendar-picker .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-calendar-date {
  background-color: #ddd !important;
}

.calendar-picker
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-disabled.ant-picker-cell-today
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value {
  color: white !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date {
  background: white !important;
}

.highlighted-letters {
  color: #2d7cdc;
  font-weight: bold;
  background: none;
  padding: 0;
  margin: 0;
}

.tickets-main {
  background-color: white;
  max-width: 1180px;
  margin: auto;
  padding: 0 24px 30px;
  border-radius: 24px 24px 0px 0px;
}

.tickets-list .ant-tabs-nav {
  margin: 0;
}

.filter-picker .ant-checkbox-group .ant-checkbox-group-item {
  width: 100%;
  margin-bottom: 12px;
}

.alert--danger svg {
  fill: white;
}

.ant-input-prefix svg {
  fill: #111 !important;
}

.form-control .ant-input[type='text'] {
  font-size: 13px !important;
  margin-top: 6px;
}

.form-control .ant-input[type='date'] {
  font-size: 13px !important;
  margin-top: 6px;
}

.booked-tickets-tabs .ant-tabs-nav-list {
  width: 100% !important;
}

.booked-tickets-tabs .ant-tabs-tab {
  padding: 10px 12px;
  margin: 0;
  font-size: 13px;
  flex: 1;
  justify-content: center;
}

.ant-tabs-tab {
  padding: 6px !important;
}

.link-hover:hover {
  color: #2bd8d8 !important;
  text-decoration: underline !important;
}

iframe {
  display: none;
}

.ant-select-selector {
  height: 50px !important;
  display: flex;
  align-items: center;
}

.ticket-list-select .ant-select-selector {
  height: 30px !important;
  display: flex;
  align-items: center;
  border-radius: 2px !important;
  background-color: rgba(17, 17, 17, 0) !important;
  font-weight: 700 !important;
}

.ant-btn.ant-btn-block {
  border: none;
}

.hover-button:hover {
  background-color: var(--primary-color) !important;
}

.ant-modal-content,
.ant-modal-header {
  border-radius: 12px;
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color) !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: var(--primary-color);
}

.ant-radio:hover .ant-radio-inner {
  border-color: var(--primary-color);
}

.ant-steps-item-process .ant-steps-item-icon {
  background: var(--primary-color) !important;
  border: none !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: var(--primary-color) !important;
  border: none !important;
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: #ffffff !important;
}

.ant-steps-item-finish .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title::after {
  background: var(--primary-color) !important;
}

.ant-steps-item .ant-steps-item-container .ant-steps-item-title {
  margin-right: 3rem !important;
  white-space: nowrap !important;
}

.custom_drawer_antd .ant-drawer-header {
  background-color: #ffffff !important;
}

.custom_drawer_sidemenu .ant-drawer-close {
  padding: 0 0 0 258px !important;
}

.ant-drawer-header-title {
  justify-content: end !important;
}
.ant-drawer-close {
  margin-right: 0px !important;
}
.ant-table-thead .ant-table-cell {
  background-color: var(--secondary-color) !important;
  font-weight: 700 !important;
}
