.text--center {
	text-align: center;
}

.flex--row {
	display: flex;
	flex-direction: row;
}

.jc--between {
	justify-content: space-between;
}

/**
Custom css
 */
.styled-header {
	background-color: orange;
	height: 35px;
	position: absolute;
	top: -3%;
	left: -1px;
	color: white;
	font-weight: 700;
	font-size: 14px;
	padding: 0 40px;
	display: flex;
	align-items: center;
	z-index: 2;
	gap: 10px;
}

.ebk-picker.ant-picker {
	padding: 10px 12px;
	border-radius: 6px;
	width: 100%;

	.ant-picker-input > input {
		padding-left: 1.1rem;
	}

	.ant-picker-suffix {
		color: initial;
		left: 0;
		margin-left: initial;
		position: absolute;
	}
}